/* eslint-disable max-classes-per-file */
import React, { useState } from "react";
import { Control, NodeEditor } from "rete";

const ReteReactControl: React.FC = (_, { name }) => {
  const [nameState, setName] = useState(name || "");

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
  };

  return <input value={nameState} onChange={onNameChange} />;
};

export class MyControl extends Control {
  render: string;

  component: React.FC;

  props: {
    emitter: NodeEditor;
    id: string;
    name: string;
  };

  constructor(emitter: NodeEditor, key: string, name: string) {
    super(key);
    this.render = "react";
    this.component = ReteReactControl;
    this.props = {
      emitter,
      id: key,
      name
      // eslint-disable-next-line prefer-spread
    };
  }
}
