import React, { useEffect, useState } from "react";

import { Data as ReteData } from "rete/types/core/data";
import { setTimeout } from "timers";
import withArguments from "../utils/withArguments";
import Page from "../components/Page";
import Container from "../components/Container";
import IndexLayout from "../layouts";

import ReteContainer from "../components/rete/ReteContainer";
import Modal from "../components/Tailwind/Modal";
import Spinner from "../components/Tailwind/Spinner";

interface IndexPageProps {
  urlArgs: {
    flowID: string;
  };
}

// The naming of this component will change over time.
const IndexPage: React.FC<IndexPageProps> = ({ urlArgs }) => {
  const [workflowLoading, setWorkflowLoading] = useState(true);
  const [reteWorkflowState, setReteWorkflowState] = useState<ReteData>({
    id: "PPoDS@0.1.0",
    nodes: {}
  });

  useEffect(() => {
    const { flowID } = urlArgs; // Parse the flow ID to query Firebase with
    setTimeout(() => {
      setReteWorkflowState({
        id: "PPoDS@0.1.0",
        nodes: {
          "1": {
            id: 1,
            data: {},
            inputs: { num1: { connections: [] } },
            outputs: {
              num: { connections: [{ node: 2, input: "num1", data: {} }] }
            },
            position: [-285.5, -105.375],
            name: "Untitled"
          },
          "2": {
            id: 2,
            data: {},
            inputs: {
              num1: {
                connections: [
                  { node: 1, output: "num", data: { big: "chungus" } }
                ]
              }
            },
            outputs: { num: { connections: [] } },
            position: [-16.5, -99.375],
            name: "Untitled"
          }
        }
      });
      setWorkflowLoading(!workflowLoading);
    }, 5000);
  }, []);

  return (
    <IndexLayout>
      <Page>
        <Container>
          <div>
            <h1 className="font-semibold text-2xl">Workflow Test</h1>
            <div style={{ borderBottom: "2px solid #eaeaea" }}>
              <ul className="flex cursor-pointer">
                <li className="py-2 px-6 bg-white rounded-t-lg">Graph</li>
              </ul>
            </div>
          </div>
          {workflowLoading ? (
            <Spinner />
          ) : (
            <ReteContainer reteJSON={reteWorkflowState} />
          )}
          <Modal />
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default withArguments(IndexPage);
