import Rete, { Node, NodeEditor } from "rete";
import ReactRenderPlugin from "rete-react-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
// import ContextMenuPlugin from "rete-context-menu-plugin";
// import MinimapPlugin from "rete-minimap-plugin";
import AreaPlugin from "rete-area-plugin";
import {
  NodeData,
  WorkerInputs,
  WorkerOutputs,
  Data
} from "rete/types/core/data";
import { MyNode } from "./Node";
import { MyControl } from "./Control";

const numSocket = new Rete.Socket("Number value");

class AddComponent extends Rete.Component {
  constructor(name = "Untitled") {
    super(name);
  }

  builder(node: Node): Promise<void> {
    const inp = new Rete.Input("num1", "Number", numSocket);
    const out = new Rete.Output("num", "Number", numSocket);
    const ctrl = new MyControl(
      this.editor as NodeEditor,
      "greeting",
      "#username"
    );
    node.addInput(inp).addOutput(out).addControl(ctrl);
    return Promise.resolve(undefined); // used to return a void promise
  }

  // eslint-disable-next-line class-methods-use-this
  worker(node: NodeData, _inputs: WorkerInputs, _outputs: WorkerOutputs): void {
    // eslint-disable-next-line no-console
    console.log(node.data);
  }
}

/**
 * This initializes the Rete container
 * @param container
 */
export default async function (container: HTMLElement, reteData: Data) {
  const components = [new AddComponent(), new AddComponent("hello")];

  const editor = new Rete.NodeEditor("PPoDS@0.1.0", container);
  editor.use(AreaPlugin, {
    snap: true
  });
  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin, {
    component: MyNode
  });
  // editor.use(ContextMenuPlugin);
  // editor.use(MinimapPlugin);

  const engine = new Rete.Engine("PPoDS@0.1.0");

  // eslint-disable-next-line array-callback-return
  components.map((c): void => {
    editor.register(c);
    engine.register(c);
  });

  editor.on(
    [
      "process",
      "nodecreated",
      "noderemoved",
      "connectioncreated",
      "connectionremoved"
    ],
    async () => {
      await engine.abort();
      await engine.process(editor.toJSON());
    }
  );

  // editor.fromJSON({
  //   id: "PPoDS@0.1.0",
  //   nodes: {
  //     "1": {
  //       id: 1,
  //       data: {},
  //       inputs: { num1: { connections: [] } },
  //       outputs: {
  //         num: { connections: [{ node: 2, input: "num1", data: {} }] }
  //       },
  //       position: [-285.5, -105.375],
  //       name: "Untitled"
  //     },
  //     "2": {
  //       id: 2,
  //       data: {},
  //       inputs: {
  //         num1: {
  //           connections: [{ node: 1, output: "num", data: { big: "chungus" } }]
  //         }
  //       },
  //       outputs: { num: { connections: [] } },
  //       position: [-16.5, -99.375],
  //       name: "Untitled"
  //     }
  //   }
  // });
  editor.fromJSON(reteData);

  editor.view.resize();
  AreaPlugin.zoomAt(editor);
  editor.trigger("process");
}
