import React from "react";
import { Location, WindowLocation, NavigateFn } from "@reach/router";
import queryString, { ParsedQuery } from "query-string";

const withArguments = (
  ComponentToWrap: React.FC<
    any & {
      location: WindowLocation;
      navigate: NavigateFn;
      urlArgs: ParsedQuery<String>;
    }
  >
): React.FC => props => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        urlArgs={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
);

export default withArguments;
