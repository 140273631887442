import React from "react";
import { Data } from "rete/types/core/data";
import init from "./Editor";

export default ({ reteJSON }: { reteJSON: Data }) => (
  <>
    <div style={{ textAlign: "left", width: "100%", height: "70vh" }}>
      <div
        ref={(el): void => {
          if (el != null) {
            init(el as HTMLElement, reteJSON);
          }
        }}
      />
    </div>
  </>
);
